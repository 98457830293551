export interface DialogStylesType {
  defaultProps?: {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    className?: string;
  };
  valid?: {
    sizes?: string[];
  };
  styles: {
    base: {
      backdrop: object;
      container: object;
      animation: object;
      body: object;
      backdropAnimation: object;
    };
    sizes?: {
      xs?: object;
      sm?: object;
      md?: object;
      lg?: object;
      xl?: object;
      xxl?: object;
    };
  };
}

export const dialog: DialogStylesType = {
  defaultProps: {
    size: 'md',
    className: '',
  },
  valid: {
    sizes: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
  },
  styles: {
    base: {
      backdrop: {
        width: 'w-screen',
        height: 'h-screen',
      },
      container: {
        position: 'absolute',
        outline: 'outline-0',
        color: 'text-color',
        fontSmoothing: 'antialiased',
        fontFamily: 'font-inter',
        fontSize: 'text-base',
        fontWeight: 'font-light',
        lineHeight: 'leading-relaxed',
      },
      body: {
        position: 'relative',
        display: 'flex',
        flex: 'flex-auto',
        p: 'p-6',
        color: 'text-color',
        background: 'bg-color-evaluated',
        fontSmoothing: 'antialiased',
        fontFamily: 'font-inter',
        fontSize: 'text-base',
        fontWeight: 'font-light',
        lineHeight: 'leading-relaxed',
      },
      animation: {
        unmount: {
          opacity: 0,
          transition: {
            duration: 0.3,
          },
        },
        mount: {
          opacity: 1,
          transition: {
            duration: 0.3,
          },
        },
      },
      backdropAnimation: {
        unmount: {
          opacity: 0,
          transition: {
            delay: 0.2,
          },
        },
        mount: {
          opacity: 1,
        },
      },
    },
    sizes: {
      xs: {
        width: 'w-full lg:w-2/5',
        maxWidth:
          'max-w-[90%] sm:max-w-[70%] md:max-w-[50%] lg:max-w-[50%] xl:max-w-[40%] 2xl:max-w-[30%]',
        position: 'top-10',
      },
      sm: {
        width: 'w-full lg:w-2/5',
        minWidth: 'min-w-[300px] lg:min-w-[400px]',
        maxWidth: 'max-w-[400px] sm:max-w-[56%] sm:max-w-[35%] lg:max-w-[25%]',
        position: 'top-10',
      },
      md: {
        width: 'w-full sm:w-3/5',
        minWidth: 'min-w-[90%] sm:min-w-[30%]',
        maxWidth: 'max-w-[90%] sm:max-w-[40%] lg:max-w-[25%]',
        position: 'top-10',
      },
      lg: {
        width: 'w-3/5',
        minWidth: 'min-w-[40%]',
        maxWidth: 'max-w-[40%]',
        position: 'top-10',
      },
      xl: {
        width: 'w-full sm:w-3/4',
        minWidth: 'min-w-[75%]',
        maxWidth: 'max-w-full sm:max-w-[75%]',
        position: 'top-10',
      },
      xxl: {
        display: 'flex',
        flexDirection: 'flex-col',
        minHeight: 'min-h-[100vh]',
        maxHeight: 'max-h-[100vh]',
        borderRadius: 'rounded-none',
        position: 'top-0',
      },
    },
  },
};

export default dialog;
