export enum EnumMessageTypes {
  SUCCESS = 'success',
  ERROR = 'error',
}

const MessageTypes = {
  [EnumMessageTypes.SUCCESS]: {
    color: 'text-color-success',
    textSize: 'text-xl',
  },
  [EnumMessageTypes.ERROR]: {
    color: 'text-color-error',
    textSize: 'text-xl',
  },
};

export default MessageTypes;
