export interface PaginationStylesType {
  defaultProps?: {
    className?: string;
  };
  styles: {
    paginationBase: {
      container: {
        display: string;
        gap: string;
        itemPosition: string;
        margin: string;
      };
      paginationButtonBase: {
        rounded: string;
        display: string;
        itemPosition: string;
        cursor: string;
        background: string;
        width: string;
        height: string;
        textColor: string;
        textSize: string;
      };
      paginationButtonDisabled: {
        opacity: string;
        textColor: string;
        cursor: string;
      };
      paginationButtonActive: {
        background: string;
      };
      paginationDetail: {
        textColor: string;
        textSize: string;
      };
    };
  };
}

const pagination: PaginationStylesType = {
  defaultProps: {
    className: '',
  },
  styles: {
    paginationBase: {
      container: {
        display: 'flex',
        gap: 'gap-2',
        itemPosition: 'justify-center items-center sm:justify-start',
        margin: 'mt-4',
      },
      paginationButtonBase: {
        rounded: 'rounded-lg',
        display: 'flex',
        itemPosition: 'items-center justify-center',
        cursor: 'cursor-pointer',
        background: 'bg-color-evaluated',
        width: 'w-[30px]',
        height: 'h-[30px]',
        textColor: 'text-white',
        textSize: 'text-xs',
      },
      paginationButtonDisabled: {
        opacity: 'opacity-50',
        textColor: 'text-purple',
        cursor: 'cursor-not-allowed',
      },
      paginationButtonActive: {
        background: 'bg-color-blue',
      },
      paginationDetail: {
        textColor: 'text-white',
        textSize: 'text-sm',
      },
    },
  },
};

export default pagination;
