import checkboxColors from './checkbox-colors';
import checkboxIcons from './checkbox-icons';
import { CheckboxColor } from '~constants/etc';

export interface CheckboxStylesType {
  defaultProps?: {
    color?: CheckboxColor.DEFAULT | CheckboxColor.DANGER;
    label?: string;
    className?: string;
    labelProps?: Record<string, string>;
  };
  valid?: {
    colors?: string[];
    icons?: string[];
  };
  styles?: {
    base?: {
      root?: {
        display: string;
        alignItems: string;
      };
      container?: {
        position: string;
        display: string;
        alignItems: string;
        cursor: string;
        p: string;
        borderRadius: string;
      };
      input?: {
        peer: string;
        position: string;
        appearance: string;
        width: string;
        height: string;
        borderRadius: string;
        cursor: string;
        transition: string;
      };
      icon?: {
        position: string;
        opacity: string;
        cursor: string;
        text: string;
      };
      label?: {
        color: string;
        fontWeight: string;
        userSelect: string;
        cursor: string;
      };
      disabled?: {
        cursor: string;
        opacity: string;
      };
    };
    colors: typeof checkboxColors;
    icons: typeof checkboxIcons;
  };
}

export const checkbox: CheckboxStylesType = {
  defaultProps: {
    color: CheckboxColor.DEFAULT,
    label: '',
    className: '',
    labelProps: {},
  },
  valid: {
    colors: [CheckboxColor.DEFAULT, CheckboxColor.DANGER],
    icons: [CheckboxColor.DEFAULT, CheckboxColor.DANGER],
  },
  styles: {
    base: {
      root: {
        display: 'inline-flex',
        alignItems: 'items-center',
      },
      container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'items-center',
        cursor: 'cursor-pointer',
        p: 'p-2',
        borderRadius: 'rounded-full',
      },
      input: {
        peer: 'peer',
        position: 'relative',
        appearance: 'appearance-none',
        width: 'w-4',
        height: 'h-4',
        borderRadius: 'rounded',
        cursor: 'cursor-pointer',
        transition: 'transition-all',
      },
      icon: {
        position: 'absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4',
        opacity: 'opacity-0 peer-checked:opacity-100 transition-opacity',
        cursor: 'pointer-events-none',
        text: 'text-xs',
      },
      label: {
        color: 'text-white',
        fontWeight: 'font-light',
        userSelect: 'select-none',
        cursor: 'cursor-pointer',
      },
      disabled: {
        cursor: 'cursor-not-allowed',
        opacity: 'opacity-80',
      },
    },
    colors: checkboxColors,
    icons: checkboxIcons,
  },
};

export default checkbox;
