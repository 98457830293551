import { CheckboxColor } from '~constants/etc';

const checkboxColors: object = {
  [CheckboxColor.DEFAULT]: {
    border: 'border border-color-checkbox',
  },
  [CheckboxColor.SUCCESS]: {
    border: 'border border-color-success',
  },
  [CheckboxColor.DANGER]: {
    border: 'border border-color-error',
  },
};

export default checkboxColors;
