export function objectsToArray(
  object: Record<string, string> | undefined,
): string[] {
  let result: string[] = [];

  if (!object) {
    return [];
  }

  Object.values(object).forEach((value) => {
    if (typeof value === 'string') {
      result = [...result, value];
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      result = [...result, ...objectsToArray(value)];
    }

    return undefined;
  });

  return result;
}
