import { NoteColor } from '~constants/etc';
import noteColors from '~themes/components/note/note-colors';

export interface NoteStylesType {
  defaultProps?: {
    color?: NoteColor;
    className?: string;
  };
  valid?: {
    colors?: NoteColor[];
  };
  styles: {
    base: {
      container: {
        display: string;
        width: string;
        gap: string;
        rounded: string;
        border: string;
        background: string;
        padding: string;
        text: string;
      };
      icon: {
        display: string;
        justify: string;
        text: string;
      };
      text: {
        grow: string;
      };
    };
    colors: typeof noteColors;
  };
}

export const note: NoteStylesType = {
  defaultProps: {
    color: NoteColor.DEFAULT,
    className: '',
  },
  valid: {
    colors: [NoteColor.DEFAULT, NoteColor.SUCCESS, NoteColor.DANGER],
  },
  styles: {
    base: {
      container: {
        display: 'flex',
        width: 'w-full',
        gap: 'gap-2',
        rounded: 'rounded-2xl',
        border: 'border border-color-success',
        background: 'bg-color-alert-success',
        padding: 'p-4',
        text: 'text-color-success',
      },
      icon: {
        display: 'flex',
        justify: 'justify-start',
        text: 'text-xl pt-1',
      },
      text: {
        grow: 'grow text-xs sm:text-sm',
      },
    },
    colors: noteColors,
  },
};

export default note;
