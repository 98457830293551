import MessageTypes, {
  EnumMessageTypes,
} from '~themes/components/message/message-types';

export interface MessageStylesType {
  valid?: {
    types: object;
  };
  styles: {
    base: {
      wrapper: {
        position: string;
        zIndex: string;
        display: string;
        translate: string;
      };
      container: {
        position: string;
        width: string;
        margin: string;
        lineHeight: string;
        background: string;
        radius: string;
        color: string;
        shadow: string;
      };
      body: {
        padding: string;
        display: string;
        gap: string;
        alignItem: string;
        textSize: string;
      };
    };
  };
  types: typeof MessageTypes;
}

const Message: MessageStylesType = {
  valid: {
    types: Object.values(EnumMessageTypes),
  },
  styles: {
    base: {
      wrapper: {
        position: 'fixed top-0 left-[50%]',
        zIndex: 'z-50',
        display: 'flex flex-col',
        translate: '-translate-x-[50%]',
      },
      container: {
        position: 'relative block',
        width: 'min-w-[120px]',
        margin: 'mt-2',
        lineHeight: 'leading-3',
        background: 'bg-white',
        radius: 'rounded-xl',
        color: 'text-black',
        shadow: 'shadow',
      },
      body: {
        padding: 'p-3',
        display: 'flex',
        gap: 'gap-2',
        alignItem: 'items-center justify-center',
        textSize: 'text-sm font-bold',
      },
    },
  },
  types: MessageTypes,
};

export default Message;
