import { ButtonColors } from '~constants/etc';

const buttonGhost = {
  [ButtonColors.DEFAULT]: {
    border: 'border border-color-button',
    color: 'text-button-default',
  },
  [ButtonColors.PRIMARY]: {
    border: 'border border-color-primary',
    color: 'text-color-primary',
  },
  [ButtonColors.SUCCESS]: {
    border: 'border border-color-success',
    color: 'text-color-success',
  },
  [ButtonColors.DANGER]: {
    border: 'border border-color-error',
    color: 'text-color-error',
  },
  [ButtonColors.TRANSPARENT]: {
    border: 'border border-transparent',
    color: 'text-color-primary',
  },
};

export default buttonGhost;
