import Input from './components/input';
import Button from './components/button';
import Checkbox from './components/checkbox';
import Dialog from './components/dialog';
import Note from './components/note';
import Tooltip from './components/tooltip';
import Pagination from './components/pagination';
import Message from './components/message';

const theme = {
  input: Input,
  button: Button,
  checkbox: Checkbox,
  dialog: Dialog,
  note: Note,
  tooltip: Tooltip,
  pagination: Pagination,
  message: Message,
};

export default theme;
