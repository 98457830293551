import { CheckboxColor } from '~constants/etc';

const checkboxIcons: object = {
  [CheckboxColor.DEFAULT]: {
    color: 'text-checkbox-color-base',
  },
  [CheckboxColor.SUCCESS]: {
    color: 'text-color-success',
  },
  [CheckboxColor.DANGER]: {
    color: 'text-textColor-color-error',
  },
};

export default checkboxIcons;
