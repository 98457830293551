export interface TooltipStylesType {
  styles: {
    base: {
      bg: string;
      padding: string;
      borderRadius: string;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      color: string;
      outline: string;
      overflowWrap: string;
      zIndex: string;
      display: string;
      whiteSpace: string;
    };
  };
}

export const tooltip: TooltipStylesType = {
  styles: {
    base: {
      bg: 'bg-black',
      padding: 'p-3',
      borderRadius: 'rounded-lg',
      fontFamily: 'font-prompt',
      fontSize: 'text-sm',
      fontWeight: 'font-normal',
      color: 'text-white',
      outline: 'focus:outline-none',
      overflowWrap: 'break-words',
      zIndex: 'z-[999]',
      display: 'block',
      whiteSpace: 'whitespace-normal',
    },
  },
};

export default tooltip;
