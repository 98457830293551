import { NoteColor } from '~constants/etc';

const NoteColors: object = {
  [NoteColor.DEFAULT]: {
    background: 'bg-color-alert-warning',
    border: 'border border-color-warning',
    text: 'text-color-warning',
  },
  [NoteColor.SUCCESS]: {
    background: 'bg-color-alert-success',
    border: 'border border-color-success',
    text: 'text-color-success',
  },
  [NoteColor.DANGER]: {
    background: 'bg-color-alert-error',
    border: 'border border-color-error',
    text: 'text-color-error',
  },
  [NoteColor.GHOST]: {
    background: 'bg-color-evaluated',
    border: 'border border-color-input',
    text: 'text-color',
  },
};

export default NoteColors;
